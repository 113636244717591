import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";

export const CommunityResources = styled.div`
  .footer {
    @media ${device.tabletMax} {
      margin: 180px auto 0;
    }
  }
	.pagewrapper {
		&:before {
			height: calc(100% + 50px);

			@media ${device.laptop} and ${device.ipadair2} {
				height: calc(100% - 0px);
			}
		}
	}

	&.community-resources {
    .internal-banner__large-title{
      @media ${device.desktopsignup} and ${device.midDesktopMax}{
        padding-left: 30px !important;
      }
    }
		.o-bottom-space {
			.logo-block {
				&.btm-logo-block {
					ul {
						width: 100% !important;

						li {
							a {
								img {
									width: 300px;

									@media ${device.laptopMedium} {
										width: 110px;
									}

									@media ${device.mobileMaxPortrait} {
										width: 95px;
									}

									@media ${device.tablet} and ${device.midDesktopMax} {
										width: 225px;
									}
								}
							}
						}
					}
				}
			}
      .logo-block{
        &.o-image-wrapper-main{
          ul{
            &:nth-child(2) {
              li{
                &:first-child{
                  a{
                    img{
                      @media ${device.desktopStart} {
                        max-width: 80%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
		}
    .title-card{
      .o-inner{
        .footer-block{
          .footer-inner-block{
            padding: 28px 16px 16px;
            @media ${device.ipadLandscapemin}{
              padding: 38px 24px 133px;
            }
            a{
              @media ${device.ipadLandscapemin}{
                bottom: 30px
              }
            }
          }
        }
      }
    }
	}
`;

export const RepWrapper  = styled.div`
.o-paragraph{
    margin-bottom: 40px;
}
	.video-thumbnail-section {
		.video-thumbnail-section-container {
			&:first-child {
				.thumbnail-video-section {
					padding: 28px 0 0 0;
					@media ${device.laptop} {
						padding-top: 75px;
					}
					p {
						padding-bottom: 32px;
						position: relative;
						font-size: 2.2rem;
    				line-height: 2.6rem;
						color: #000000;
						text-align: center;
						font-weight: 500;
						margin: auto;
						margin-bottom: 32px;

						@media ${device.ipadLandscapemin} {
							font-size: 3.4rem;
							line-height: 4.7rem;
							color: #000000;
							text-align: center;
							font-weight: 500;
							margin: auto;
							padding: 0;
						}

						&:after {
							content: "";
							background-color: #8000BE;
							position: absolute;
							bottom: 0;
							width: 74%;
							height: 1px;
							left: 0;
							right: 0;
							margin: auto;
							display: none;

							@media ${device.laptopMedium} {
								display: block;
							}
						}
					}
				}
			}

			&:last-child {
				padding-bottom: 10px;
				.thumbnail-video-section {
					p,h2 {
						padding: 0 0 0 0;
						font-size: 2.5rem;
    				line-height: 3rem;
						color: #000000;
    				text-align: center;
						margin-bottom: 0;

						@media ${device.laptopPros} {
							width: 95%;
						}

						@media ${device.ipadLandscapemin} {
						font-size: 4.2rem;
						font-weight: 700;
						line-height: 5rem;
						color: #000000;
						text-align: center;
						padding-top: 40px;
						padding-bottom: 56px;
						}
					}
				}
			}
		}
	}
`;

export const FooterImageGroup = styled.div`
	.o-inner {
		padding-left: 84px;
		padding-right: 84px;

		@media ${device.ipadLandscape} {
			padding-left: 0;
    	padding-right: 0;
		}
		.o-internal--h2 {
			font-size: 4.2rem;
			font-weight: 700;
			line-height: 5rem;
			color: #000000;
			text-align: center;
			margin-bottom: 0;

			@media ${device.ipadLandscape} {
				font-size: 2.5rem;
    		line-height: 3rem;
			}
		}
		.o-other-texts {
			p {
				color: #000000;
				text-align: center;
				max-width: 768px;
				margin: auto;
				padding: 40px 0 44px 0;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.ipadLandscape} {
					padding: 19px 4px 21px 4px;
					font-size: 1.8rem;
    			line-height: 2.2rem;
				}
			}
		}

		.o-text-wrap {
			p {
				max-width: 100%;
				text-align: center;
				strong {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					text-align: center;
          color: #000000;
				}
			}
			&:nth-child(2) {
				p {
					color: #000000;
					text-align: center;
					margin: auto;
					padding: 0;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 25px !important;
    				line-height: 3rem !important;
					}
					strong {
						@media ${device.laptopMedium} {
							font-size: 25px !important;
							line-height: 3rem !important;
						}
					}
				}
			}
			&:nth-child(3) {
				p {
					color: #000000;
					text-align: center;
					max-width: 768px;
					margin: auto;
					padding: 40px 0 44px 0;
          font-family: "Gotham", Arial, Helvetica, sans-serif;
					font-size: 22px;
					font-weight: 500;

					@media ${device.laptopMedium} {
						padding: 19px 4px 21px 4px;
						font-size: 1.8rem;
						line-height: 2.2rem;
						font-weight: 500;
						color: #000000;

					}
				}
			}
			&:nth-child(4) {
				p,h2 {
					font-weight: 700;
					color: #8000BE;
					text-align: center;
					padding-bottom: 42px;
					font-size: 2.7rem;
					line-height: 3.8rem;

					strong {
						font-weight: 700;
						color: #8000BE;
						text-align: center;
						padding-bottom: 42px;
						font-size: 2.7rem;
						line-height: 3.8rem;
					}
				}
				@media ${device.laptopMedium} {
					padding-bottom: 24px;
					margin: auto;
					font-size: 2rem;
					line-height: 2.4rem;
					font-weight: 700;
					color: #8000BE;
					text-align: center;
				}
				strong {
					@media ${device.laptopMedium} {
						padding-bottom: 24px;
						width: 70%;
						margin: auto;
						font-size: 2rem;
						line-height: 2.4rem;
						font-weight: 700;
						color: #8000BE;
						text-align: center;
					}
				}
			}
		}
		ul {
    		justify-content: center;
				list-style: none;
				width: 68%;
				margin: -6px auto;
				padding: 0;

				@media ${device.laptopMedium} {
					width: 100%;
					display: table;
					margin: 0 auto;
				}

			li {
				height: 187px;
				line-height: 187px;
				text-align: center;
				border-right: 1px solid #707070;
				border-bottom: 1px solid #707070;
				align-items: center;
				justify-content: center;
				float: left;
				width: 33%;

				&:last-child {
					border-right: none;

					img {
						max-width: 100%;
					}
				}

				img {
					@media ${device.ipadLandscape} {
						width: 70%;
					}

					@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
						width: 70%;
					}
				}
			}

			&:last-child {
				li {
					border-bottom: none;
				}
			}

      &:first-child {
        li {
          &:last-child {
            img {
              max-width: 80%;
            }

            p.logo-garden-desc {

              @media ${device.mobileMaxPortrait} {
                padding-top: 45px !important;
              }

              @media ${device.ipadair} {
                padding-top: 86px !important;
              }

              @media ${device.smalldeskstrt} {
                padding-top: 50px !important;
              }
            }
          }
        }
      }
		}

		.btm-logo-block {
			ul {
				margin: 0 auto;
				display: flex;
			}
		}
	}
`;

export const GenTestBenefitWrapperMain = styled.div`
  .community-resources {
      &:before{
        @media ${device.ipadLandscapemin} and  ${device.desktopmenu} {
          top: -55px;
        }
      }

    .video-container {
      .o-inner {
        .video-thumbnail-section-main {
          div.video-thumbnail-right {
            .o-header {
              p {
                strong {
                  font-weight: 600;
                }
              }
            }

            span.o-paragraph {
              p {
                font-size: 1.8rem !important;
                line-height: 2.6rem !important;
                font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
                color: #000000;
                max-width: 100%;
              }
            }
          }
          .video-section__inner-content__video-btn {
              @media ${device.ipadLandscapemin} {
                box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px 0px;
              }

            .dtp-arrow-down{
              &:before{
                width: 21px;
                height: 11px;
                margin-left: 8px;
              }
              &.rotate {
                  top: -4px;
                  margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  .generic-testing-benefits {
    .early-testing-benefita-container {
      .testing-list-bg {
        .o-container {
          .bg-color-component {
            .listing-box {
              .o-top-space {
                .genetic-points-paragraphs {
                  ul {
                    li {
                      @media ${device.laptopMedium} {
                        padding: 6px 0 6px 24px;
                        font-size: 1.8rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .genetic-left-img-points {
      .left-image-points {
        .listing-btm-section {
          ul {
            &.right-description {
              li {
                &:nth-child(2) {
                  margin-left: 25px;

                  &:before {
                    border-radius: 50%;
                  }
                }

                &:before {
                  @media ${device.tablet} and ${device.laptopMedium} {
                    top: 11px;
                  }

                  @media ${device.laptopPros} {
                    top: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .o-container {
    padding: 0 2rem;

    @media ${device.laptopPros} {
      padding: 0 2.7rem;
    }

    @media ${device.ipadLandscape} {
      max-width: 718px;
    }
    .title-card{
      margin: 40px 0;
      @media ${device.ipadLandscapemin} {
        margin: 0px 0px 120px;
      }
    }
  }

  .o-backtotop-btn {
    @media ${device.ipadLandscape} {
      top: -100px;
    }
  }

  .logo-section {
    .o-inner {
        p{
          text-align: center;
          margin: auto;
          width: 70%;
          @media ${device.ipadLandscapemin}{
            width: 100%;
            padding-bottom: 32px;
          }
          @media ${device.desktopsignup}{
            margin-bottom: 16px;
          }
          strong{
            color: rgb(128, 0, 190);
            font-size: 2rem;
            line-height: 2.4rem;
            text-align: center;
            @media ${device.ipadLandscapemin} {
              font-size: 2.7rem;
              line-height: 3.8rem;
            }
          }
        }
        .second-heading {
          padding: 48px 0px 24px;
          width: 70%;
          margin: 0 auto 13px;
          @media ${device.ipadLandscapemin} {
            padding-bottom: 42px;
            padding-top: 88px;
            width: auto;
            margin-bottom: 0.5rem;
          }
            strong {
              @media ${device.laptopMedium} {
                line-height: 2.6rem;
              }
            }
        }
        .o-other-texts{
          p{
            margin-bottom: 0;
            width: 100%;
            @media ${device.ipadLandscapemin} {
              padding-bottom: 44px;
            }
          }
        }
      .o-text-wrap {
        text-align: center;
        padding-bottom: 66px;
        @media ${device.ipadLandscapemin} {
          padding-bottom: 42px;
        }
        strong{
          color: rgb(128, 0, 190);
          font-size: 2.7rem;
          line-height: 3.8rem;
        }
        &:nth-child(2) {
          p {
            @media ${device.laptopPros} {
              padding: 0;
            }
          }
        }

        &:nth-child(4) {
          p {
            @media ${device.laptopMedium} {
              font-size: 2rem;
              line-height: 2.4rem;
              padding-bottom: 0;
              margin: 0;
            }

            strong {
              @media ${device.laptopMedium} {
                font-size: 2rem;
                line-height: 2.4rem;
                padding-bottom: 0;
              }
            }
          }
        }
      }

      .logo-block {
        ul {
          @media ${device.laptopMedium} {
            width: 100% !important;
          }

          li {
            @media ${device.mobileMedium} {
              height: 100px;
              line-height: 100px;
            }
          }
        }
      }

      .btm-logo-block {
        @media ${device.laptopMedium} {
          padding-bottom: 80px;
        }
      }

      .btm-logo-title {
        @media ${device.laptopMedium} {
          padding: 48px 0 0px;
          width: 70%;
          margin: auto;
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }
  }

  .title-card {
    background-color: #300064;
    margin:  0 0 120px 0;
    padding: 87px 100px;

    @media ${device.ipadLandscape} {
      margin: 40px 0;
      padding: 44px 43px 48px;
    }

    .o-inner {
      padding: 0;

      h2 {
        color: #fff;
        text-align: center;
        padding-bottom: 4rem;
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 5rem;

        @media ${device.ipadLandscape} {
          padding-bottom: 19px;
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      .footer-block {
        padding: 0;
        margin: 0;
        display: flex;
        width: 100%;

        @media ${device.ipadLandscape} {
          display: block;
        }
        @media ${device.desktopStart} {
          height: 312px;
        }

        .footer-inner-block {
          text-align: center;
          position: relative;
          border-radius: 5px;
          background-color: #fff;
          box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
          padding: 38px 24px 120px;
          margin: 0 16px;
          width: calc(50% - 26px);

          @media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
            padding: 38px 24px 133px;
          }

          @media ${device.ipadLandscape} {
            border-radius: 10px;
            padding-top: 28px;
            padding-bottom: 16px;
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 20px;
            width: 100%;
          }

          &:before {
            content: "";
            background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
            width: 100%;
            height: 16px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px 5px 0 0;

            @media ${device.ipadLandscape} {
              border-radius: 10px 10px 0 0;
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(2) {
            &:before {
              background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
            }

            @media ${device.ipadLandscape} {
              margin-left: 0;
              margin-top: 15px;
            }
          }

          p,.title-card-heading,.o-common-title {
            max-width: 400px;
            font-size: 2.7rem;
            line-height: 3.8rem;
            color: #8000BE;
            text-align: center;
            font-weight: 700;
            margin: 0;

            @media ${device.ipadLandscape} {
              font-size: 2rem;
              line-height: 2.4rem;
              padding-bottom: 15px;
            }
          }

          a {
            left: 23px;
            right: 23px;
            bottom: 30px;
            padding: 12px 23px 12px 23px;
            display: table;
            align-items: center;
            box-shadow: 0px 3px 4px #d6d6d6;
            position: absolute;
            width: 90%;
            text-align: center;
            margin: 48px auto 0;
            background: #8000BE;
            color: #fff;
            border: 1px solid #fff;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 2.4rem;
            text-decoration: none;
            z-index: 1;
            vertical-align: top;
            overflow: hidden;
            letter-spacing: 0;
            border-radius: 6px;
            cursor: pointer;

            @media ${device.ipadLandscape} {
              display: block;
              position: initial;
              font-size: 1.8rem;
              line-height: 2.6rem;
              margin: 15px auto 0;
              width: 100%;
            }

            &:hover {
              background: #fff;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
              box-shadow: 0px 3px 4px #d6d6d6;
            }

            &:after {
              content: '';
              display: inline-block;
              height: 20px;
              width: 16px;

              @media ${device.ipadLandscape} {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

  .clinical-trials-banner{
    .internal-banner{
      @media ${device.laptopMedium}{
        height: 500px;
        width: 100%;
      }
      .gatsby-image-wrapper-constrained{
        width:100%;

        @media ${device.laptopMedium}{
          height: 500px;
          object-position: 90% 0%;
        }
        @media ${device.desktopsignup}{
          margin-top: -2px;
        }
        @media ${device.largeDesktop}{
          height: 380px;
        }
        @media ${device.extraLargeDesktop}{
          height: 410px;
        }
      }
      &:after{
        @media ${device.largeDesktop}{
          top: calc(100% - 32px);
        }
      }
      .internal-banner__contant{
        .o-container{
          h1{
            @media ${device.mobileMedium} {
              line-height: 3.8rem;
            }
            @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
              margin: 15px 0;
              width: 100%;
              font-family: "Gotham", Arial, Helvetica, sans-serif;
              margin-left: 3rem;
            }
            @media ${device.desktopsignup} and ${device.midDesktopMax}{
              padding-left: 30px;
            }
            @media ${device.desktopStart}{
              padding-left: 0;
            }
          }
        }
      }
    }
  }

`;
