import React from 'react'
import { graphql } from 'gatsby'
import { Layout, HTMLRenderer, PageWrapper } from '@retina-packages/retina-theme-bootstrap'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import  MultiColVideoContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MultiColVideoContainer';
import { ExitPopUpType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { patientCS } from './constants';
import { deriveCommunityResourceVideo, exitPopUpBuilder } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import { CommunityResources, FooterImageGroup, GenTestBenefitWrapperMain, RepWrapper } from './styles';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

const CommunutyResourceTemplate = (props: Props) => {
  /**
   * Global section data building
   */
  const pgProp: PageQueryResult = props.data
  const blocksCO = pgProp.nodePage.relationships.field_paragraphs_gcso
  const validCOBlocks = blocksCO.filter((x: any) => 'relationships' in x)

  const pgContextsCS = props.pageContext
  const htmlStaticFilesCS = {
    nonSVGImages: pgContextsCS.nonSVGImages,
    svgMediaImages: pgContextsCS.svgMediaImages,
    allMediaDocument: pgContextsCS.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientCS.mainMenu, siteFooterMenu: patientCS.patFooterMenu });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const footerHTML = fullHTMLDataBuilder({ blocks: blocksCO, title: patientCS?.footer });

  const exitPopDataIRD = validCOBlocks.filter(
      (epird: ExitPopUpType) =>
        epird?.relationships?.field_reusable_paragraph?.label ===
        patientCS.exitPopup
    )
    .map((epirdi: any) => exitPopUpBuilder(epirdi))
    ?.at(0);

  const hcpSwitcherLinksIRD = validCOBlocks
    .filter(
      (hsird: ExitPopUpType) =>
        hsird?.relationships?.field_reusable_paragraph?.label ===
        patientCS.hcpSwitcher
    )
    .map(
      (hsirdi: any) =>
        hsirdi.relationships.field_reusable_paragraph.relationships.paragraphs
          .relationships.field_paragraph_items_gcso
    )
    ?.at(0);

  const siteLogos = deriveMultiLogo({blocks: blocksCO, title: patientCS.siteLogo})
  const bannerHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.banner})
  const topNavHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.topNav})

  /**
   * Page specific data building
   */
  const comResVideoSectionCO = validCOBlocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === patientCS.video1).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)

  const videoTopParaHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.videoTopPara})
  const communitySupData = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.titleCard})
  const titleCardHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.crTitleCard})
  return (
    <GenTestBenefitWrapperMain className='community-resource-main-wrapper'>
      <CommunityResources className='community-resources'>
        {pgContextsCS !== null && pgContextsCS.metaInfo !== null && (
          <MetaTagContainer metaData={pgContextsCS.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          data={mainMenu}
          mdata={footerMenu}
          footerData={footerHTML}
          audience={audience}
          exitPopData={exitPopDataIRD}
          hcplinks={hcpSwitcherLinksIRD}
          topNavigation={topNavHTML}
          siteLogos={siteLogos}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          backToTopContent={retinaConfig.backToTopContent}
          gtmBackToTopLabel={patientCS.backToTopGTM}
          staticMediaFiles={htmlStaticFilesCS}
          hcpValidate={retinaConfig.hcpValidate}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          preIndexUrl={retinaConfig.preIndexUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={patientCS.footerClassName}
          languageConfig={{... retinaConfig.langConfig, ...patientCS.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <PageWrapper className="pagewrapper community-resources">
            {/* Banner section */}
            <MobileBanner className="mobile-banner clinical-trials-banner">
              <HTMLRenderer data={htmlStaticFilesCS} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true}/>
            </MobileBanner>
            {/* First callout of page */}
            <RepWrapper className='o-container'>
              <HTMLRenderer data={htmlStaticFilesCS} html={videoTopParaHTML} tagName='div' className='o-inner' />
            </RepWrapper>
            <MultiColVideoContainer
              accountId={retinaConfig.accountId}
              communityResourcesVideoSection={comResVideoSectionCO}
              viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
              hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
              { ...patientCS.videoAttrs }
            />
            <HTMLRenderer data={htmlStaticFilesCS} html={titleCardHTML}/>
            <FooterImageGroup className='logo-section o-column--full o-bottom-space '>
              <HTMLRenderer data={htmlStaticFilesCS} html={communitySupData} tagName='div' className='o-container' simpleImage={true} />
            </FooterImageGroup>
          </PageWrapper>
        </Layout>
      </CommunityResources>
    </GenTestBenefitWrapperMain>
  )
}

export default CommunutyResourceTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... CommunityResourcePageQuery
      }
    }
  }
`
