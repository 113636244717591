export const patientCS: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO",
  footerMenu: "Footer Navigation Menu Consumer GCSO",
  banner: "Banner - HTML - Community Resources - DTC - Canada",
  footer: "Footer DTC HTML Canada",
  exitPopup: "Retina Canada Exit Popup HTML",
  hcpSwitcher: "HCP Switcher Modal Pop Up",
  topNav: "Top Navigation Menu Retina Canada HTML DTC",
  video1: "Community Resources Video Section",
  siteLogo: "Site logos DTC",
  video1GTMLabel: "Genes 101",
  video2GTMLabel:  "Inheritance 101",
  videoTopPara: "Community Resources Video Thumbnail Heading HTML",
  crTitleCard: "Community Resources Title Card",
  titleCard: "Logo garden section - Canada - DTC - Community Resources",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/community-resources",
      "fr": "/fr/community-resources"
    }
  },
  backToTopGTM: "Back to top - community resources",
  videoAttrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Genes 101 - View transcript",
        "className": "gtm-accordion",
        "aria-label": "View transcript for Genes 101"
      },
      {
        "data-gtm-event-label": "Inheritance 101 - View transcript",
        "className": "gtm-accordion",
        "aria-label": "View transcript for Inheritance 101"
      },
      {
        "data-gtm-event-label": "The genetic testing story of Ramya - View transcript",
        "className": "gtm-accordion",
        "aria-label": "View transcript for The genetic testing story of Ramya"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Genes 101 - Hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Genes 101"
      },
      {
        "data-gtm-event-label": "Inheritance 101 - Hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Inheritance 101"
      },
      {
        "data-gtm-event-label": "The genetic testing story of Ramya - Hiew transcript",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for The genetic testing story of Ramya"
      }
    ]
  },
  footerClassName: "community-resources-footer"
}

